import * as React from "react";
import styled from "styled-components";
import {Column, Row} from "../theme/Grid";
import {colors} from "../utils/theme";

const CONTACT_DATA = {
    transport: {
        items: [
            {
                name: "Katarzyna Sadło (j.niemiecki)",
                phone: "+48 696 041 539",
                email: "transport@picars.pl"
            },
            {
                name: "Mariusz Reczka (j.rosyjski)",
                phone: "+48 608 463 852",
                email: "m.reczka@picars.pl"
            },
            {
                name: "Szymon Garbacz (j.angielski)",
                phone: "+48 535 063 072",
                email: "s.garbacz@picars.pl"
            }
        ],
    },
    logistic: {
        items: [
            {
                name: "Karol Pituch",
                phone: "+48 530 220 760",
                email: "k.pituch@picars.pl"
            }
        ]
    },
    service: {
        items: [
            {
                name: "Wojciech Maciąg",
                phone: "+48 606 952 175",
                email: "sklep@picars.pl",
            },
            {
                name: "Mariusz Twardowski",
                phone: "+48 534 279 276",
                email: "sklep@picars.pl",
            }
        ]
    },
    contact: {
        items: [
            {
                titleKey: "contact.office",
                name: "Agnieszka Reczka",
                phone: "+48 793 101 023",
                email: "biuro@picars.pl"
            },
            {
                titleKey: "contact.finances",
                name: "Grażyna Pituch",
                phone: "+48 606 244 897",
                email: "finanse@picars.pl"
            },
            {
                titleKey: "contact.invoices",
                name: "Justyna Pożoga",
                email: "faktury@picars.pl"
            }
        ]
    }
}

const ContactItem = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 25px;

  a {
    text-decoration: none;
    color: ${colors.secondaryColor};
  }
`

const ItemWrapper = styled.div`
    // border-top: 1px solid ${colors.primaryColor};
  border-left: ${({border = true}) => border && `1px solid ${colors.primaryColor}`};
  padding: 20px 0;

  //border-left: 0;
  text-align: center;
  //border-right: 0;
`

const ContactData = ({category}) => {
    const data = CONTACT_DATA[category];
    if (!data) {
        return null;
    }
    return <>
        <Row>
            {data.items.map((item, index) => <Column width={10 / data.items.length}>
                <ItemWrapper border={index !== 0}>
                    {item.titleKey && <ContactItem><strong>{item.titleKey}</strong></ContactItem>}
                    {item.name && <ContactItem>{item.name}</ContactItem>}
                    {item.phone && <ContactItem>{item.phone}</ContactItem>}
                    {item.email && <ContactItem><a href={`mailto:${item.email}`}>{item.email}</a></ContactItem>}
                </ItemWrapper>

            </Column>)}
        </Row>
        {data.email && <Row>
            <ContactItem><a href={`mailto:${data.email}`}>{data.email}</a></ContactItem>
        </Row>}
    </>
}

export default ContactData;