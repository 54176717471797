import * as React from "react";
import styled from "styled-components";
import {Container, Column, Row} from "../theme/Grid";
import HexagonImage from "./HexagonImage";
import AboutImage from '../images/about-image.jpg';
import {colors} from "../utils/theme";
import {Content, H2, H3} from "../theme/Typography";

const Wrapper = styled.section`
  display: flex;
  background: ${colors.primaryColor};
  padding: 40px 0 80px 0;
`;

const About = ({lang, id}) => (
    <Wrapper id={id}>
        <Container>
            <Row>
                <Column width={5}>
                    {lang === 'pl' && <>
                        <H2>O nas</H2>
                        <H3>Picars to zespół specjalistów działających w <strong>branży TSL</strong></H3>
                        <Content>
                            <p>
                                Z naszych usług korzysta setka zadowolonych klientów. Każdego dnia realizujemy usługi
                                transportowe i spedycyjne w całej
                                Europie. Przy wykorzystaniu ponad 60 zestawów ciężarowych prowadzimy działalność na
                                takich
                                rynkach jak Niemcy, Francja, Hiszpania, Benelux, Wielka Brytania czy Turcja. Naszym
                                celem jest świadczenie usług na najwyższym poziomie jednocześnie dbając o środowisko w
                                związku z tym 90% naszego taboru to ciągniki siodłowe z najwyższą normą emisji spalin
                                Euro 6.
                                <br/>
                                <br/>
                                Picars to również usługi logistyczne i celne, w naszej dyspozycji znajduje się ponad
                                2000m2
                                miejsca magazynowego oraz skład celny przystosowany do magazynowania towarów objętych
                                cłem.
                            </p>
                        </Content>
                    </>}
                    {lang === 'en' && <>
                        <H2>About us</H2>
                        <H3>Picars is a team of specialists operating in the <strong>TSL industry</strong></H3>
                        <Content>
                            <p>
                                Our services are used by hundreds of satisfied customers. Every day we provide transport
                                and
                                forwarding services throughout Europe. With over 60 sets of trucks, we operate in such
                                markets as Germany, France, Spain, Benelux, Great Britain and Turkey. Our goal is to
                                provide
                                services at the highest level, while caring for the environment, therefore 90% of our
                                fleet
                                are tractor units with the Euro 6 exhaust emission standard.
                                <br/>
                                <br/>
                                Picars also offers logistics and customs services, we have over 2000 m2 of storage space
                                and
                                a customs warehouse adapted to the storage of goods subject to customs duties.
                            </p>
                        </Content>
                    </>}
                    {lang === 'de' && <>
                        <H2>ÜBER UNS</H2>
                        <H3>Picars ist ein Team von Spezialisten, die in der <strong>TSL-Branche</strong> tätig
                            sind.</H3>
                        <Content breakWords>
                            <p>
                                Unsere Dienstleistungen werden von Hunderten zufriedener Kunden genutzt. Jeden Tag
                                bieten
                                wir
                                Transport- und Speditionsdienste in ganz Europa an. Mit über 60 Lkw-Sätzen sind wir in
                                Märkten wie Deutschland, Frankreich, Spanien, Benelux, Großbritannien und der Türkei
                                tätig.
                                Unser Ziel ist es, Dienstleistungen auf höchstem Niveau zu bieten und gleichzeitig die
                                Umwelt zu schonen. Daher sind 90% unserer Flotte Sattelzugmaschinen mit der Abgasnorm
                                Euro
                                6.
                                <br/>
                                <br/>
                                Picars bietet auch Logistik- und Zolldienstleistungen an. Wir verfügen über mehr als
                                2000 m2
                                Lagerfläche und ein Zolllager, das an die Lagerung von zollpflichtigen Waren angepasst
                                ist.
                            </p>
                        </Content>
                    </>}
                </Column>
                <Column width={5}>
                    <HexagonImage src={AboutImage}/>
                </Column>
            </Row>
        </Container>
    </Wrapper>
)

export default About;
