import * as React from "react"
import styled from "styled-components";
import {breakpoints} from "../utils/breakpoints";
// import {breakpoints} from "../utils/breakpoints";

// const Image = styled.img`
//   clip-path: url(#svgPath);
//   width: 100%;
//   height: auto;
//   ${breakpoints.small} {
//     max-width: 400px;
//     width: 100%;
//   }
//
//   ${breakpoints.large} {
//     max-width: 100%;
//     width: 100%;
//   }
//
//   &:hover {
//     transform: scale(1.05);
//   }
// `
const Image = styled.div`
 display: inline-block;
  //width: 100%;
  color: red;
  background: url(${props => props.src}) center/cover;
  padding-top: 100%;
  clip-path: url(#svgPath);
  transition: all .3s;
  ${breakpoints.small} {
    max-width: 400px;
    width: 100%;
    padding-top: 400px;
  }

  ${breakpoints.large} {
    max-width: 100%;
    width: 100%;
    padding-top: 100%;
  }

  &:hover {
    transform: scale(1.05);
  }
`

const HexagonImage = ({src, width = '300px'}) => <Image src={src} width={width} />

export default HexagonImage;