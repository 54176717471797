import {H2} from "../theme/Typography";
import * as React from "react";
import styled from "styled-components";
import {FormattedMessage} from 'gatsby-plugin-react-intl';
import { withPrefix } from 'gatsby'

import {colors} from "../utils/theme";

const DOCS = [
    {
        labelKey: 'docs.certificate',
        file: withPrefix('Certificate.pdf')
    },
    {
        labelKey: 'docs.license',
        file: withPrefix('Licencja.pdf')
    },
    {
        labelKey: 'docs.krs',
        file: withPrefix('odpis_pelny_738510_1609328546379.pdf')
    },
    {
        labelKey: 'docs.ocp',
        file: withPrefix('polisa2021.pdf')
    }
]

const List = styled.ul`
  margin: 0;
  padding: 0;
  font-size: 22px;
  list-style: none;

  li {
    margin-bottom: 5px;
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration:none;
    :hover {
      color: ${colors.secondaryColor}
    }
  }
`

const DocsWrapper = styled.div`
  margin-top: 20px;
  font-size: 16px;
  font-weight: 300;
`

const DocsList = () => (<DocsWrapper>
        <H2><FormattedMessage id={'docs.title'}/></H2>
        <List>
            {DOCS.map(doc =>
                <li key={doc.labelKey}>
                    <a href={doc.file} target={'_blank'}>
                        <FormattedMessage id={doc.labelKey}/>
                    </a>
                </li>)}
        </List>
    </DocsWrapper>
)

export default DocsList;