import * as React from "react";
import styled from "styled-components";
import {Container, Column, Row} from "../theme/Grid";
import HexagonImage from "./HexagonImage";
import SalesImage from '../images/sales-image.jpg';
import {colors} from "../utils/theme";
import {Content, H2, H3} from "../theme/Typography";
import Button from "./Button";

const Wrapper = styled.section`
  display: flex;
  background: ${colors.primaryColor};
  padding: 80px 0;
`;

const SALES_LINK = 'https://picars.otomoto.pl/'

const Sales = ({id, lang}) => <Wrapper id={id}>
    <Container>
        <Row reverseOnMobile>
            <Column width={5}>
                <HexagonImage src={SalesImage} width="100%"/>
            </Column>
            <Column width={5}>
                {lang === 'pl' && <>
                    <H2>Sprzedaż aut i naczep</H2>
                    <H3>Picars prowadzi również <strong>sprzedaż samochodów ciężarowych oraz naczep</strong>.</H3>
                    <Content>
                        <p>W
                            naszej ofercie znajdują
                            się samochody ciężarowe (w tym ciągniki siodłowe), samochody dostawcze oraz naczepy.
                        </p>
                        <Button href={SALES_LINK}>Sprawdź naszą aktualną ofertę</Button>
                    </Content>
                </>}
                {lang === 'en' && <>
                    <H2>Sales Of Trucks and Trailers</H2>
                    <H3>Picars also <strong>sells trucks and trailers</strong>.</H3>
                    <Content>
                        <p>Our offer includes trucks (including tractor units), vans and semi-trailers.</p>

                        <Button href={SALES_LINK}>Check the current offer</Button>
                    </Content>
                </>}
                {lang === 'de' && <>
                    <H2>Verkauf von LKWs und Anhängern</H2>
                    <H3>Picars <strong>verkauft auch Lastwagen und Anhänger</strong>.</H3>
                    <Content>
                        <p>Unser Angebot umfasst LKWs (einschließlich Sattelzugmaschinen), Lieferwagen und
                            Sattelauflieger.</p>

                        <Button href={SALES_LINK}>Überprüfen Sie das aktuelle Angebot</Button>
                    </Content>
                </>}
            </Column>

        </Row>
    </Container>
</Wrapper>

export default Sales;