import * as React from "react"
import styled from "styled-components";
import {colors} from "../utils/theme";

const StyledFooter = styled.footer`
  color: ${colors.textColor};
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  padding: 25px 0;
  background: ${colors.primaryColor};
`


const Footer = () => <StyledFooter>
    <span>Picars</span> {new Date().getFullYear()}  &copy;
</StyledFooter>

export default Footer;