import * as React from "react";
//const d="M77.94228634059948 4.999999999999999Q86.60254037844386 0 95.26279441628824 4.999999999999999L164.54482671904333 45Q173.20508075688772 50 173.20508075688772 60L173.20508075688772 140Q173.20508075688772 150 164.54482671904333 155L95.26279441628824 195Q86.60254037844386 200 77.94228634059948 195L8.660254037844387 155Q0 150 0 140L0 60Q0 50 8.660254037844387 45Z";
//const d = "M0,604.4C0,523.7,30.7,408.8,97.5,320,217,160.9,409.2,0,409.2,0S597.2,167.8,717,331c63,85.7,93,196.4,93,274,0,224.5-181.3,407-405,407S0,829.5,0,604.4Z";
const d = "M1.800,341.405 C1.805,305.678 20.869,272.668 51.812,254.809 L467.578,14.843 C498.521,-3.016 536.641,-3.011 567.578,14.857 L983.279,254.938 C1014.216,272.806 1033.272,305.821 1033.267,341.548 L1033.200,821.595 C1033.195,857.322 1014.131,890.332 983.188,908.191 L567.422,1148.157 C536.479,1166.016 498.359,1166.011 467.422,1148.143 L51.721,908.062 C20.784,890.194 1.728,857.179 1.733,821.452 L1.800,341.405 Z"
const scaleX = 1 / 1033;
const scaleY = 1 / 1161;
const SVGFilter = () => <svg width="0" height="0">
    <defs>
        <clipPath id="svgPath" clipPathUnits="objectBoundingBox">
            <path d={d} transform={`scale(${scaleX}, ${scaleY})`}/>
        </clipPath>
    </defs>
</svg>
export default SVGFilter;