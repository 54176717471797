import * as React from "react";
import styled from "styled-components";
import {colors} from "../utils/theme";


const StyledButton = styled.button`
  border: 1px solid ${colors.secondaryColor};
  color: ${colors.textColor};
  font-size: 16px;
  font-weight: 300;
  padding: 10px 15px;
  font-family: 'Fira Sans';
  background: transparent;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  transition: all .3s;
  &:hover{
    background: ${colors.secondaryColor};
  }
`

const Button = ({onClick, children, href}) => {
    const handleOnClick = () => {
        if (onClick) {
            onClick()
        } else if (href) {
            window.open(href, '_blank').focus();
        }
    }
    return <StyledButton onClick={handleOnClick}
                         type={'button'}>{children}</StyledButton>
}

export default Button;