import * as React from "react"
import GlobalStyle from "../theme/GlobalStyle";
import Header from "../components/Header";
import image from '../images/about-image.jpg';
import TopBar from "../components/TopBar";
import SEO from "../components/SEO";
import {useEffect} from "react";
import {FormattedMessage} from 'gatsby-plugin-react-intl';
const HomeTemplate = ({children}) => {

    return (
        <div>
            <SEO
                image={image}
                title={'Picars.pl'}
                siteUrl={'https://picars.pl'}
                description={'Picars to zespół specjalistów działających w branży TSL'}
            />
            <GlobalStyle/>
            <TopBar/>
            <Header/>
            {children}

        </div>
    )
}

export default HomeTemplate
