import styled from "styled-components";
import {colors} from "../utils/theme";

export const TextCenter = styled.div`
  text-align: center;
`

export const H2 = styled.h2`
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 300;
  margin-top: 0;
  color: ${colors.secondaryColor}
`

export const H3 = styled.h3`
  font-size: 28px;
  font-weight: 300;

  strong {
    font-weight: 400;
    color: ${colors.secondaryColor}
  }
  a{
    text-decoration: none;
    color: ${colors.secondaryColor}
  }
`

export const Content = styled.div`
  font-size: 23px;
  line-height: 30px;
  font-weight: 300;

  ${({breakWords}) => breakWords && `word-break: break-all`};
  a {
    color: ${colors.secondaryColor};
    text-decoration: none;
    cursor: pointer;
  }
`