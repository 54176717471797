import * as React from "react";
import styled from "styled-components";
import {Container, Column, Row} from "../theme/Grid";
import HexagonImage from "./HexagonImage";
import ServiceImage from '../images/service-image.jpg';
import {colors} from "../utils/theme";
import {Content, H2, H3} from "../theme/Typography";
import ContactData from "./ContactData";

const Wrapper = styled.section`
  display: flex;
  background: ${colors.textColor};
  color: ${colors.primaryColor};
  padding: 80px 0;
`;

const Service = ({id, lang}) => <Wrapper id={id}>
    <Container>
        <Row>
            <Column width={5}>
                {lang === 'pl' && <>
                    <H2>Serwis TIR</H2>
                    <H3>Wielu zadowolonych klientów to najlepsze <strong>świadectwo jakości</strong> wykonywanych przez
                        nas usług.</H3>
                    <Content>
                        <p>
                            Posiadając różnorodną flotę samochodów ciężarowych, musimy wyróżniać się również wysokiej
                            klasy, profesjonalnym warsztatem samochodowym, by każde auto było odpowiednio sprawdzone i
                            przygotowane do trasy. W naszym warsztacie klienci mogą liczyć na sprawną wymianę olejów,
                            naprawę silników oraz skrzyń biegów i elementów zawieszenia, a także wymianę części czy
                            diagnostykę komputerową.
                        </p>
                    </Content>
                </>}
                {lang === 'en' && <>
                    <H2>TIR-Service</H2>
                    <H3>Many satisfied customers are the best <strong>proof of the quality</strong> of our
                        services.</H3>
                    <Content>
                        <p>
                            Having a diverse fleet of trucks, we must also distinguish ourselves with a high-class,
                            professional car service, so that each car is properly checked and prepared for the route.
                            In our service, customers can count on an efficient oil change, repair of engines, gearboxes
                            and suspension components, as well as replacement of parts or computer diagnostics.
                        </p>
                    </Content>
                </>}
                {lang === 'de' && <>
                    <H2>Truck Service</H2>
                    <H3>Viele zufriedene Kunden sind der beste <strong>Beweis für die Qualität</strong> unserer
                        Dienstleistungen.</H3>
                    <Content>
                        <p>
                            Mit einer vielfältigen LKW-Flotte müssen wir uns auch durch einen erstklassigen,
                            professionellen Autoservice auszeichnen, damit jedes Auto ordnungsgemäß überprüft und auf
                            die Route vorbereitet wird. In unserem Service können Kunden auf einen effizienten
                            Ölwechsel, die Reparatur von Motoren, Getrieben und Fahrwerkskomponenten sowie den Austausch
                            von Teilen oder die Computerdiagnose zählen.
                        </p>
                    </Content>
                </>}
            </Column>
            <Column width={5}>
                <HexagonImage src={ServiceImage} width="100%"/>
            </Column>
        </Row>
    </Container>
</Wrapper>

export default Service;