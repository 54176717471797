import * as React from "react"
import GlobalStyle from "../theme/GlobalStyle";
import SVGFilter from "../components/SVGFilter";
import About from "../components/About";
import HomeTemplate from "../templates/Home";
import Transport from "../components/Transport";
import Logistic from "../components/Logistic";
import Service from "../components/Service";
import Sales from "../components/Sales";
import Contact from "../components/Contact";
// import {useIntl, changeLocale} from "gatsby-plugin-react-intl"
import {useIntl} from "gatsby-plugin-react-intl"
import Footer from "../components/Footer";
// import {useEffect} from "react";

// markup
const IndexPage = () => {
    const intl = useIntl()
    // const {language} = window.navigator;
    //
    // useEffect(() => {
    //     const ISOLang = language.split('-')[0].toLowerCase();
    //     if (['pl', 'de', 'en'].includes(ISOLang)) {
    //         changeLocale(ISOLang)
    //     }
    // }, [])

    return (
        <HomeTemplate>
            <SVGFilter/>
            <About lang={intl.locale} id={'o-nas'}/>
            <Transport lang={intl.locale} id={'transport'}/>
            <Logistic lang={intl.locale} id={'logistyka'}/>
            <Service lang={intl.locale} id={'serwis'}/>
            <Sales lang={intl.locale} id={'sprzedaz'}/>
            <Contact lang={intl.locale} id={'kontakt'}/>
            <Footer/>
            <GlobalStyle/>
        </HomeTemplate>
    )
}

export default IndexPage
