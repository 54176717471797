import * as React from "react";
import styled from "styled-components";
import {Container, Column, Row} from "../theme/Grid";
import HexagonImage from "./HexagonImage";
import ContactImage from '../images/contact-image.jpg';
import {colors} from "../utils/theme";
import {Content, H2, H3} from "../theme/Typography";
import DocsList from "./DocsList";


const Wrapper = styled.section`
  display: flex;
  background: ${colors.textColor};
  color: ${colors.primaryColor};
  padding: 80px 0;
`;

const ContactDataList = styled.ul`
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;

  &:last-of-type {
    margin: 0;
  }

  li {
    margin-bottom: 5px;

    strong {
      font-weight: 400;
    }
  }
`

const EMAIL_ADDRESS = 'biuro@picars.pl';
const TRANSPORT_EMAIL = 'transport@picars.pl';
const TRANS_EU = '23622';
const PHONE_NUMBER = '+48 602 571 821';
const OFFICE_NUMBER = '+48/41 273 00 55';
const NIP_NUMBER = '664 213 81 28';
const REGON_NUMBER = '36790287600000';
const KRS_NUMBER = '0000738510';

const Service = ({id, lang}) => <Wrapper id={id}>
    <Container>
        <Row>
            <Column width={5}>
                {lang === 'pl' && <>
                    <H2>Kontakt</H2>
                    <H3>Picars Maciej Pituch Sp. k.</H3>
                    <Content>
                        <ContactDataList>
                            <li>ul.Składowa 5, 27-200 Starachowice</li>
                            <li>tel: <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a></li>
                            <li>biuro/fax: <a href={`tel:${OFFICE_NUMBER}`}>{OFFICE_NUMBER}</a></li>
                            <li>e-mail: <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a></li>
                            <li>transport: <a href={`mailto:${TRANSPORT_EMAIL}`}>{TRANSPORT_EMAIL}</a></li>
                            <li>trans.eu: <strong>{TRANS_EU}</strong></li>
                        </ContactDataList>
                        <ContactDataList>
                            <li>NIP PL: <strong>{NIP_NUMBER}</strong></li>
                            <li>REGON: <strong>{REGON_NUMBER}</strong></li>
                            <li>KRS: <strong>{KRS_NUMBER}</strong></li>
                        </ContactDataList>
                    </Content>
                </>}
                {lang === 'en' && <>
                    <H2>Contact</H2>
                    <H3>Picars Maciej Pituch Sp. k.</H3>
                    <Content>
                        <ContactDataList>
                            <li>ul.Składowa 5, 27-200 Starachowice</li>
                            <li>mobile: <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a></li>
                            <li>office/fax: <a href={`tel:${OFFICE_NUMBER}`}>{OFFICE_NUMBER}</a></li>
                            <li>e-mail: <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a></li>
                            <li>transport: <a href={`mailto:${TRANSPORT_EMAIL}`}>{TRANSPORT_EMAIL}</a></li>
                            <li>trans.eu: <strong>{TRANS_EU}</strong></li>
                        </ContactDataList>
                        <ContactDataList>
                            <li>NIP PL: <strong>{NIP_NUMBER}</strong></li>
                            <li>REGON: <strong>{REGON_NUMBER}</strong></li>
                            <li>KRS: <strong>{KRS_NUMBER}</strong></li>
                        </ContactDataList>
                    </Content>
                </>}
                {lang === 'de' && <>
                    <H2>Kontakt</H2>
                    <H3>Picars Maciej Pituch Sp. k.</H3>
                    <Content>
                        <ContactDataList>
                            <li>ul.Składowa 5, 27-200 Starachowice</li>
                            <li>mobile: <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a></li>
                            <li>Büro/fax: <a href={`tel:${OFFICE_NUMBER}`}>{OFFICE_NUMBER}</a></li>
                            <li>e-mail: <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a></li>
                            <li>transport: <a href={`mailto:${TRANSPORT_EMAIL}`}>{TRANSPORT_EMAIL}</a></li>
                            <li>trans.eu: <strong>{TRANS_EU}</strong></li>
                        </ContactDataList>
                        <ContactDataList>
                            <li>NIP PL: <strong>{NIP_NUMBER}</strong></li>
                            <li>REGON: <strong>{REGON_NUMBER}</strong></li>
                            <li>KRS: <strong>{KRS_NUMBER}</strong></li>
                        </ContactDataList>
                    </Content>
                </>}

                <DocsList />

            </Column>
            <Column width={5}>
                <HexagonImage src={ContactImage}/>
            </Column>
        </Row>
    </Container>
</Wrapper>

export default Service;
