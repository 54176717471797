import * as React from "react";
import styled from "styled-components";
import {Container, Column, Row} from "../theme/Grid";
import {colors} from "../utils/theme";
import {Content, H2, H3, TextCenter} from "../theme/Typography";
import {breakpoints} from "../utils/breakpoints";
import img1 from '../images/transport1-img.jpg';
import img2 from '../images/transport2-img.jpg';
import img3 from '../images/transport3-img.jpg';
import img4 from '../images/transport4-img.jpg';
import img5 from '../images/transport5-img.jpg';
import ContactData from "./ContactData";

const SectionWrapper = styled.section`
  display: flex;
  background: ${colors.textColor};
  margin-top: 0;
  color: ${colors.primaryColor};
  padding: 80px 0;
`;

const StyledGallery = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const GalleryItem = styled.li`
  font-size: 0;
  margin: 0;
  padding: 10px;
  height: 200px;
  overflow: hidden;
  z-index: 2;

  ${breakpoints.small} {
    width: 33%;
  }

  ${breakpoints.large} {
    width: 20%;
  }

  img {
    cursor: zoom-in;
    width: 100%;
    height: auto;
    //height: 100%;
    //object-fit: cover;
    border-radius: 5px;
  }

  &:hover {
    transform: scale(1.5);
    transition: all .3s;
    z-index: 5;

    ${breakpoints.small} {
      transform: scale(1);
    }

    //overflow: visible;
    //display: flex;
    //z-index: 3;
    //align-items: center;
    //
    //img {
    //  height: auto;
    //  opacity: 1;
    //  object-fit: unset;
    //}
  }
`

const IMAGES = [
    img1, img2, img3, img4, img5
]


const About = ({id, lang}) => <SectionWrapper id={id}>
    <Container>
        <Row>
            <Column width={10}>
                <TextCenter>
                    {lang === 'pl' && <>
                        <H2>Transport i spedycja</H2>
                        <H3>Transport z nami to najlepszy sposób na <strong>szybki i bezpieczny</strong> przewóz
                            towaru</H3>
                        <Content>
                            <p>
                                Podstawą działalności naszej firmy jest świadczenie usług transportowych na najwyższym
                                poziomie. Zajmujemy się przewozem towarowym w relacjach zarówno krajowych, jak i
                                międzynarodowych, a doświadczeni kierowcy są gwarantem terminowego dowozu. Dzięki
                                różnorodnej flocie, w której znajduje się 60 samochodów ciężarowych, możemy oferować
                                transport gabarytów ważących od 0,5 do 24 ton.
                            </p>
                        </Content>
                    </>}
                    {lang === 'en' && <>
                        <H2>Transport and shipping</H2>
                        <H3>Transport with us is the best way to ship goods <strong>quickly and safely</strong></H3>
                        <Content>
                            <p>
                                The basis of our company's activity is to provide transport services at the highest
                                level. We deal with freight transport in both domestic and international relations, and
                                experienced drivers are a guarantee of timely delivery. Thanks to a diverse fleet of 60
                                trucks, we can offer transport of dimensions weighing from 0.5 to 24 tons.
                            </p>
                        </Content>
                    </>}
                    {lang === 'de' && <>
                        <H2>Transport und Versand</H2>
                        <H3>Der Transport mit uns ist der beste Weg, um Waren <strong>schnell und sicher</strong> zu
                            versenden.</H3>
                        <Content>
                            <p>
                                Die Basis unserer Geschäftstätigkeit ist die Bereitstellung von
                                Transportdienstleistungen auf höchstem Niveau. Wir befassen uns sowohl im Inland als
                                auch im Ausland mit dem Güterverkehr, und erfahrene Fahrer garantieren eine pünktliche
                                Lieferung. Dank einer vielfältigen Flotte von 60 LKWs können wir Transporte mit
                                Abmessungen von 0,5 bis 24 Tonnen anbieten.
                            </p>
                        </Content>
                    </>}
                </TextCenter>
            </Column>
        </Row>
        <Row>
            <StyledGallery>
                {IMAGES.map((img, index) => (
                    <GalleryItem key={`transport-image-${index}`} src={img}>
                        <img src={img} alt={`transport-image-${index}`}/>
                    </GalleryItem>
                ))}
            </StyledGallery>
        </Row>

        {/*<ContactData category={'transport'}/>*/}
    </Container>
</SectionWrapper>

export default About;