import * as React from "react";
import styled from "styled-components";
import {Container, Column, Row} from "../theme/Grid";
import HexagonImage from "./HexagonImage";
import LogisticImage from '../images/logistic-image.jpg';
import {colors} from "../utils/theme";
import {Content, H2, H3} from "../theme/Typography";
import ContactData from "./ContactData";

const Wrapper = styled.section`
  display: flex;
  background: ${colors.primaryColor};
  padding: 80px 0;
`;

const Logistic = ({id, lang}) => <Wrapper id={id}>
    <Container>
        <Row reverseOnMobile>
            <Column width={5}>
                <HexagonImage src={LogisticImage}/>
            </Column>
            <Column width={5}>
                {lang === 'pl' && <>
                    <H2>Logistyka</H2>
                    <H3>Dbamy o to, by klient był zadowolony na <strong>każdym etapie</strong></H3>
                    <Content>
                        <p>
                            Picars ma w dyspozycji ponad 2000m2 powierzchni magazynowej przeznaczonej dla usług
                            logistycznych. Posiadamy również skład celny, który umożliwia długoterminowe magazynowanie
                            towarów objętych cłem np. z poza Unii Europejskiej.
                            <br/>
                            Nasze usługi obejmują ofertę pełnej obsługą logistyczną dla małych firm handlowych i
                            produkcyjnych w zakresie dostaw, magazynowania i dystrybucji.
                        </p>
                    </Content>
                </>}
                {lang === 'en' && <>
                    <H2>Logistics</H2>
                    <H3>We make sure that the client is satisfied at <strong>every stage</strong></H3>
                    <Content>
                        <p>
                            Picars has over 2,000 m2 of warehouse space at its disposal for logistics services. We also
                            have a bonded warehouse, which enables long-term storage of goods subject to customs duties,
                            e.g. from outside the European Union.
                            <br/>
                            Our services include an offer of full logistics services for small trade and production
                            companies in the field of delivery, storage and distribution.
                        </p>
                    </Content>
                </>}
                {lang === 'de' && <>
                    <H2>Logistik</H2>
                    <H3>Wir sorgen dafür, dass der Kunde in <strong>jeder Phase</strong> zufrieden ist</H3>
                    <Content>
                        <p>
                            Picars verfügt über mehr als 2.000 m2 Lagerfläche für Logistikdienstleistungen. Wir haben
                            auch ein Zolllager, das die langfristige Lagerung von zollpflichtigen Waren ermöglicht, z.
                            von außerhalb der Europäischen Union.
                            <br/>
                            Unsere Dienstleistungen umfassen ein Angebot an umfassenden Logistikdienstleistungen für
                            kleine Handels- und Produktionsunternehmen im Bereich Lieferung, Lagerung und Vertrieb.
                        </p>
                    </Content>
                </>}
                {/*<ContactData category={'logistic'}/>*/}
            </Column>
        </Row>
    </Container>
</Wrapper>

export default Logistic;